import React from 'react';
import Layout from 'components/layout';
import SeoComponent from 'components/seo';
import { Hero, Breadcrumb } from "components/ui";
import TwoColumnContent from "templates/two-column-content-layout.js";
import { useStrapiStatisticsPageData } from 'hooks';
import { getSrc } from "gatsby-plugin-image";
import { CardContainer} from "components/containers/Cards";
// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined"
const groupBy = (array, key) => {
    return array?.reduce((result, currentValue) => {
      (result[currentValue?.category?.name] = result[currentValue?.category?.name] || []).push(
        currentValue
      );
      return result;
    }, {});
  };

const ChildWelfareStatistics = ({location}) => {
    const { strapiStatisticsPage } = useStrapiStatisticsPageData()
    const seo = {
        metaTitle: strapiStatisticsPage?.title,
        metaDescription: strapiStatisticsPage?.description,
        metaKeywords: strapiStatisticsPage?.keywords
      }
      let thisLocationPathname = ''

     if (isBrowser) {
         thisLocationPathname = window.location.pathname
     }
     const createKey = title => {
        let keyId = title.toLowerCase().replace(" ", "-");
        return keyId
      }
     const groupByCategory = groupBy([...strapiStatisticsPage?.resources,{
      slug: "interactive-statistics-series",
      title: "Interactive Statistics Series",
      card_title: "Interactive Statistics Series",
      resources: [],
      external_resource: false,
      category: {
          name: "Policy and Practice Resources"
      },
      author: "Adoption and Foster Care Analysis and Reporting System (AFCARS) and Treatment Episode Data Set Admissions (TEDS-A)",
      resource_date: "2020",
      body: {
          data: {
              childMarkdownRemark: {
                  excerpt: "<p>This page contains data from 1) the Adoption and Foster Care Analysis and Reporting System (AFCARS) depicting national and state informa…</p>",
                  rawMarkdownBody: "This page contains data from 1) the Adoption and Foster Care Analysis and Reporting System (AFCARS) depicting national and state information related to parental alcohol or drug abuse a condition associated with removal and 2) the Treatment Episode Data Set Admissions (TEDS-A) depicting substance use disorder treatment admissions by gender and substance type."
              }
          }
      }
  }], 'category')
     const categoryResources = Object.entries(groupByCategory).map(([categoryName, resourcesData]) => ({ categoryName, resourcesData }));
     const sortTopics=topics=>{
        const topicsOrder=["Policy and Practice Resources", "Related Online Training, Videos, and Webinars", "State and Local Examples", "Additional Resources"]
        let sortedTopics=topics.sort((currentTopic,topic)=>topicsOrder.indexOf(currentTopic?.categoryName)-topicsOrder.indexOf(topic?.categoryName))
        return sortedTopics;
      }
      return (
    <Layout location={location}>
        <SeoComponent title={seo.metaTitle} description={seo.metaDescription} keywords={seo.metaKeywords} />
        <div>
            <Hero
            img={getSrc(strapiStatisticsPage?.hero_image?.background_image?.localFile)}
            color="#f5f5f5"
            isFixedHeight={false}
            >
                <Breadcrumb pathname={thisLocationPathname} customCurrentPage={seo.metaTitle}/>
                <h1 name="main-content" id="main-content" className="usa-hero__heading">{strapiStatisticsPage?.hero_image?.headline}</h1>
            </Hero>
        </div>
        <div className="padding-y-5">
            <TwoColumnContent>
                <TwoColumnContent.ColumnOne>
                    {strapiStatisticsPage?.text &&
                    <div
                        dangerouslySetInnerHTML={{
                        __html: strapiStatisticsPage?.text?.data?.childMarkdownRemark?.html,
                        }}
                        className="margin-top-0 unordered-list-style"
                    />
                    }
                </TwoColumnContent.ColumnOne>
            </TwoColumnContent>
        </div>
        {categoryResources && sortTopics(categoryResources)?.map((category, idx) => {
            let bgColorIndex = idx+1;
          return (<CardContainer
            key={`topic-card-group-${idx+1}`}
            title={category?.categoryName}
            resources={category?.resourcesData}
            keyName={createKey(category?.categoryName)}
            containerStyle={bgColorIndex % 2 === 0 ? "": "light-gray-bg"}
            cardColor="bg-white"
            grid="desktop:grid-col-6"
            topicPage={false}
            />
          )
        })}
    </Layout>
  );
};

export default ChildWelfareStatistics;
